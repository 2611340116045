import React from 'react'; //eslint-disable-line
import MainLoader from './mainLoader';

function Loading() {
  return (
    <div className="text-center">
      <MainLoader href="www.eeat-haccp.io" imagePath={`${process.env.BASE_URL}/pictures/logos/main_logo.svg`} alt="Logo" />
    </div>
  );
}
export default Loading;
