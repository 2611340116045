import { createReducer } from './utils';

import {
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGIN_USER_REQUEST,
  LOGOUT_USER,
  REGISTER_USER_FAILURE,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  CHANGE_LANGUAGE,
  SET_APPLICATIONS,
  SET_ENVIRONMENT,
  ADD_ENVIRONMENT,
  REMOVE_ENVIRONMENT,
  LOADING_FILES,
  NOT_LOADING_FILES,
  RELOAD,
  RELOAD_DONE,
  SET_RELOAD_FUNCTION,
  SUBSCRIBED_TO_PUSH,
  ENV_HAS_CHANGED_TO_FALSE,
  SET_UPDATE,
  DATA_TO_SYNC,
  SET_REWARD,
  SAVE_USER,
} from '../constants/index';

function initApp() {
  const item = localStorage.getItem('environment');
  if (!item) {
    localStorage.setItem('environment', JSON.stringify([]));
    return [];
  }
  try {
    const parsedItem = JSON.parse(item);
    if (Array.isArray(parsedItem)
    && parsedItem.length > 0
    && parseInt(parsedItem[0], 10)) {
      return parsedItem;
    }
    localStorage.setItem('environment', JSON.stringify([]));
    return [];
  } catch (err) {
    localStorage.setItem('environment', JSON.stringify([]));
    return [];
  }
}

function initLanguage() {
  const languages = process.env.LANGUAGE.map((el) => el.tag);
  const currentLanguage = localStorage.getItem('userLan');
  return languages.includes(currentLanguage) ? currentLanguage : languages[0];
}

const initialState = {
  token: null,
  perms: [],
  loadingFiles: false,
  userId: null,
  isAuthenticated: false,
  isAuthenticating: false,
  statusText: null,
  isRegistering: false,
  isRegistered: false,
  registerStatusText: null,
  language: initLanguage(),
  apps: [],
  environment: initApp(),
  username: '',
  reloading: false,
  reloadFunction: null,
  subscribedToPush: false,
  isChangingEnv: false,
  hasUpdate: false,
  sw: null,
  dataSync: false,
  reward: false,
  user: { firstname: '?', lastname: '?' },
};

export default createReducer(initialState, {
  [ENV_HAS_CHANGED_TO_FALSE]: (state) => ({
    ...state,
    isChangingEnv: false,
  }),
  [SET_ENVIRONMENT]: (state, { environment }) => {
    localStorage.setItem('environment', JSON.stringify(environment));
    return {
      ...state,
      environment,
    };
  },
  [SET_UPDATE]: (state, { hasUpdate, sw }) => ({
    ...state,
    hasUpdate,
    sw,
  }),
  [ADD_ENVIRONMENT]: (state, { app, change }) => {
    localStorage.setItem('environment', JSON.stringify([...state.environment, app.id]));
    return {
      ...state,
      apps: [...state.apps, app],
      environment: [...state.environment, app.id],
      isChangingEnv: change,
    };
  },
  [REMOVE_ENVIRONMENT]: (state, payload) => {
    localStorage.setItem('environment', JSON.stringify(state.environment.filter((el) => el !== payload.id)));
    return {
      ...state,
      apps: state.apps.filter((el) => el.id !== payload.id),
      environment: state.environment.filter((el) => el !== payload.id),
    };
  },
  [SET_APPLICATIONS]: ({ environment, ...state }, { apps }) => {
    let newEnvironments = [];

    if (apps) {
      if (environment.length > 0) {
        newEnvironments = apps.filter((el) => environment.includes(el.id)).map((el) => el.id);
      } else {
        newEnvironments = apps.map((el) => el.id);
      }
    }

    localStorage.setItem('environment', JSON.stringify(newEnvironments));
    return {
      ...state,
      environment: newEnvironments,
      apps,
    };
  },
  [CHANGE_LANGUAGE]: (state, payload) => ({
    ...state,
    language: payload.language,
  }),
  [SAVE_USER]: (state, payload) => ({
    ...state,
    user: {
      firstname: payload.firstname,
      lastname: payload.lastname,
    },
  }),
  [LOGIN_USER_REQUEST]: (state) => ({
    ...state,
    isAuthenticating: true,
    statusText: null,
  }),
  [LOGIN_USER_SUCCESS]: (state, { userVals }) => {
    localStorage.setItem('token', userVals.token);
    localStorage.setItem('userLan', userVals.lan);
    return {
      ...state,
      isAuthenticating: false,
      isAuthenticated: true,
      token: userVals.token,
      perms: userVals.perms,
      userId: userVals.userId,
      statusText: 'TEST',
      username: userVals.username,
      user: {
        firstname: userVals.firstname,
        lastname: userVals.lastname,
      },
    };
  },
  [LOGIN_USER_FAILURE]: (state) => {
    localStorage.removeItem('token');
    localStorage.removeItem('environment');
    return {
      ...state,
      isAuthenticating: false,
      isAuthenticated: false,
      token: null,
      perms: [],
      userId: null,
      statusText: 'TEST',
      username: '',
      environment: [],
      subscribedToPush: false,
    };
  },
  [LOGOUT_USER]: (state) => {
    localStorage.removeItem('token');
    return {
      ...state,
      isAuthenticated: false,
      token: null,
      perms: [],
      userId: null,
      statusText: 'TEST',
      username: '',
      subscribedToPush: false,
    };
  },
  [REGISTER_USER_SUCCESS]: (state, payload) => ({
    ...state,
    isAuthenticating: false,
    isAuthenticated: true,
    isRegistering: false,
    token: payload.userVals.token,
    perms: payload.userVals.perms,
    registerStatusText: 'TEST',
  }),
  [REGISTER_USER_REQUEST]: (state) => ({
    ...state,
    isRegistering: true,
  }),
  [REGISTER_USER_FAILURE]: (state) => ({
    ...state,
    isAuthenticated: false,
    token: null,
    perms: [],
    userId: null,
    registerStatusText: 'TEST',
    username: '',
  }),
  [LOADING_FILES]: (state) => ({
    ...state,
    loadingFiles: true,
  }),
  [NOT_LOADING_FILES]: (state) => ({
    ...state,
    loadingFiles: false,
  }),
  [SET_RELOAD_FUNCTION]: (state, payload) => ({
    ...state,
    reloadFunction: payload,
  }),
  [RELOAD]: (state) => {
    if (state.reloadFunction) state.reloadFunction();
    else {
      document.body.classList.add('refreshing');
      window.location.reload();
    }
    return {
      ...state,
      reloading: true,
    };
  },
  [RELOAD_DONE]: (state) => {
    if (!state.reloadFunction) {
      document.body.classList.remove('refreshing');
    }
    return {
      ...state,
      reloading: false,
    };
  },
  [SUBSCRIBED_TO_PUSH]: (state, payload) => ({
    ...state,
    subscribedToPush: payload,
  }),
  [DATA_TO_SYNC]: (state, payload) => ({
    ...state,
    dataSync: payload,
  }),
  [SET_REWARD]: (state, { reward }) => ({
    ...state,
    reward,
  }),
});
