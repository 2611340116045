import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import importAllfrCH from 'translations/frCH/importAll';
import importAllenUS from 'translations/enUS/importAll';
import importAlldeDE from 'translations/deDE/importAll';
import importAllitIT from 'translations/itIT/importAll';

const resources = {
  enUS: importAllenUS,
  frCH: importAllfrCH,
  itIT: importAllitIT,
  deDE: importAlldeDE,
};

i18next
  .use(LanguageDetector) // detect user language: https://github.com/i18next/i18next-browser-languageDetector
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({ // for all options read: https://www.i18next.com/overview/configuration-options
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: 'frCH',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources,
  });

export default i18next;
