// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/Cosimo - Regular-205TF.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: 'CosimoRegular205TF';   /*Can be any text*/\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\n  font-weight: normal;\n}\n\n* {\n  font-family: 'CosimoRegular205TF', 'Arial', Times, serif;\n}\n", "",{"version":3,"sources":["webpack://./src/app.scss"],"names":[],"mappings":"AAAA;EACE,iCAAiC,IAAI,kBAAkB;EACvD,+DAAiE;EACjE,mBAAmB;AACrB;;AAEA;EACE,wDAAwD;AAC1D","sourcesContent":["@font-face {\n  font-family: 'CosimoRegular205TF';   /*Can be any text*/\n  src: url('./fonts/Cosimo - Regular-205TF.ttf') format(\"truetype\");\n  font-weight: normal;\n}\n\n* {\n  font-family: 'CosimoRegular205TF', 'Arial', Times, serif;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
