import React, { Suspense } from 'react'; // eslint-disable-line
import ReactDOM from 'react-dom';  // eslint-disable-line
import {
  Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'; // eslint-disable-line
import { createBrowserHistory } from 'history'; // eslint-disable-line
import { Provider } from 'react-redux';  // eslint-disable-line
import { ThemeProvider, createTheme } from '@mui/material/styles'; // eslint-disable-line
import { TourProvider } from '@reactour/tour'; //eslint-disable-line
import { PostHogProvider} from 'posthog-js/react'; //eslint-disable-line

// fonts
import './app.scss';

// styles
import 'styles/scss/body.scss';
import 'styles/scss/icons.scss';

import './translation';

import store from 'components/utils/store';

import retry from 'components/routes/utils/retryImport';

// import Login from './components/login';
import Body from 'components/body/body';

import Loading from 'components/routes/utils/asyncLoader';

import { MainColor } from 'styles/styleInterface';

const history = createBrowserHistory();
const theme = createTheme();

const BaseRoutesPromise = import('components/routes/main');
const BaseRoutes = React.lazy(() => retry(() => BaseRoutesPromise));

const SRoutesPromise = import('components/routes/sroutes');
const SRoutes = React.lazy(() => retry(() => SRoutesPromise));

const options = {
  api_host: 'https://eu.posthog.com',
  disable_session_recording: true,
};
ReactDOM.render(
  <TourProvider
    steps={[]}
    styles={{
      badge: (base) => ({ ...base, backgroundColor: MainColor }),
      dot: (base) => ({ ...base, backgroundColor: MainColor }),
    }}
  >
    <PostHogProvider
      apiKey={process.env.POSTHOG_API_KEY}
      options={options}
    >
      <Provider store={store}>
        <Router history={history}>
          <ThemeProvider theme={theme}>
            <Body>
              <Switch>
                <Route exact path="/">
                  <Redirect to={`${process.env.BASE_URL}/login`} />
                </Route>

                <Route exact path={`${process.env.BASE_URL}/index.html`}>
                  <Redirect to={`${process.env.BASE_URL}/login`} />
                </Route>

                <Route path="/s">
                  <Suspense fallback={<Loading />}>
                    <SRoutes />
                  </Suspense>
                </Route>

                <Route path={`${process.env.BASE_URL}/`}>
                  <Suspense fallback={<Loading />}>
                    <BaseRoutes />
                  </Suspense>
                </Route>
              </Switch>
            </Body>
          </ThemeProvider>
        </Router>
      </Provider>
    </PostHogProvider>
  </TourProvider>,
  document.getElementById(process.env.ROOT_NAME),
);
