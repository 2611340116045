import form_prospect from './form_prospect.json';
import form_siteinfo from './form_siteinfo.json';
import form_specific_data from './form_specific_data.json';
import forms from './forms.json';
import general from './general.json';
import structure from './structure.json';
import ticket from './ticket.json';
import utils from './utils.json';
import order from './order.json';
import share from './share.json';
import fedex from './fedex.json';
import qrcode from './qrcode.json';

const importAll = {
  form_prospect,
  form_siteinfo,
  form_specific_data,
  forms,
  general,
  structure,
  ticket,
  utils,
  order,
  share,
  fedex,
  qrcode,
};
export default importAll;
