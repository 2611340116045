import React from 'react'; // eslint-disable-line
import CircularProgress from '@mui/material/CircularProgress'; // eslint-disable-line
import PropTypes from 'prop-types';

import { MainColor } from 'styles/styleInterface';

const Loader = ({
  size,
  thickness,
  color,
  style,
}) => (
  <CircularProgress
    style={{ color, ...style }}
    size={size}
    thickness={thickness}
  />
);
Loader.propTypes = {
  size: PropTypes.number,
  thickness: PropTypes.number,
  color: PropTypes.string,
  style: PropTypes.shape(),
};
Loader.defaultProps = {
  size: 40,
  thickness: 3.6,
  color: MainColor,
  style: {},
};

export default Loader;
