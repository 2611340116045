import form_prospect from './form_prospect.json';
import forms from './forms.json';
import general from './general.json';
import structure from './structure.json';
import utils from './utils.json';
import fedex from './fedex.json';
import share from './share.json';

const importAll = {
  form_prospect,
  forms,
  general,
  structure,
  utils,
  fedex,
  share,
};
export default importAll;
