export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGOUT_USER = 'LOGOUT_USER';

export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE';
export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST';

export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const SET_ENVIRONMENT = 'SET_ENVIRONMENT';
export const ADD_ENVIRONMENT = 'ADD_ENVIRONMENT';
export const REMOVE_ENVIRONMENT = 'REMOVE_ENVIRONMENT';
export const SET_APPLICATIONS = 'SET_APPLICATIONS';
export const SET_UPDATE = 'SET_UPDATE';
export const DATA_TO_SYNC = 'DATA_TO_SYNC';
export const SET_REWARD = 'SET_REWARD';
export const SAVE_USER = 'SAVE_USER';

export const RELOAD = 'RELOAD';
export const RELOAD_DONE = 'RELOAD_DONE';
export const SET_RELOAD_FUNCTION = 'SET_RELOAD_FUNCTION';

export const SUBSCRIBED_TO_PUSH = 'SUBSCRIBED_TO_PUSH';

export const LOADING_FILES = 'LOADING_FILES';
export const NOT_LOADING_FILES = 'NOT_LOADING_FILES';

export const ENV_HAS_CHANGED_TO_FALSE = 'ENV_HAS_CHANGED_TO_FALSE';

export const VALID = 0;
export const NO_USER = 1;
export const NOT_ACTIVATED = 2;
export const WRONG_PASSWORD = 3;
export const NOT_CONNECTED = 4;
export const EXPIRED = 5;
export const VALID_REMOVE_APPS = 6;
export const ERROR = 7;

export const MODULES_PERMS = [
  'admin',
  'base',
];
