// import getMuiTheme from 'material-ui/styles/getMuiTheme';

// --------------------- ADMINISTRATION ----------------------------------------
import ContentAddCircleOutline from '@mui/icons-material/AddCircleOutline';
import EditorPublish from '@mui/icons-material/Publish';

const MAINCOLOR1 = '#1e2b8e';
const MAINCOLOR2 = '#E5321B';
export const GLACON = '#CAE6E6';
export const BLACK = '#1e1e1e';
export const POWDERGREEN = '#F2F6FB';
export const CAROTTE = '#E5321B';
export const PECHE = '#F9CAC2';
export const COURGETTE = '#2A4F2B';
export const COURGE = '#F9B433';
export const CARAMEL = '#D99C5E';
export const AUBERGINE = '#5B103B';
const HUBSPOTCOLOR = '#ff7a59';
const BEXIOCOLOR = '#000000de';

export const MainColor = MAINCOLOR1;
export const SecondColor = MAINCOLOR2;
export const HubspotColor = HUBSPOTCOLOR;
export const BexioColor = BEXIOCOLOR;

export const TopShift = {
  desktop: '50px',
  phone: '106px',
};

export const drawerStyle = {
  height: 'calc(100% - 50px)',
  top: '60px',
  paddingBottom: '50px',
};

export const NavBarStyle = { environment: { marginTop: '5px' } };
// -------------------------------- EEVENTS ------------------------------------
export const EeventsList = {
  fired: MAINCOLOR1,
  unfired: MAINCOLOR2,
};
export const AdministrationIcons = {
  addIcon: ContentAddCircleOutline,
  importIcon: EditorPublish,
};
export const AdministrationCreateIcon = {
  smallIcon: {
    width: 18,
    height: 18,
    marginBottom: -3,
  },
  mediumIcon: {
    width: 24,
    height: 24,
    marginBottom: -6,
  },
  largeIcon: {
    width: 60,
    height: 60,
  },
  small: {
    width: 24,
    height: 24,
    padding: 2,
  },
  medium: {
    width: 27,
    height: 27,
    padding: 2,
    left: '130px',
    top: '27px',
  },
  large: {
    width: 120,
    height: 120,
    padding: 30,
  },
  color: MAINCOLOR1,
};

export const AdministrationRemoveButton = { color: MAINCOLOR2 };

// export const AdministrationSearchField = getMuiTheme({
//   textField: {
//     focusColor: MAINCOLOR1,
//     errorColor: MAINCOLOR2,
//   },
// });

export const administrationBackButton = {
  smallIcon: {
    width: 18,
    height: 18,
    marginBottom: -3,
  },
  mediumIcon: {
    width: 30,
    height: 30,
    marginLeft: '-10px',
  },
  largeIcon: {
    width: 45,
    height: 45,
  },
  small: {
    width: 24,
    height: 24,
    padding: 2,
  },
  medium: {
    left: '0px',
    top: '25px',
    width: 60,
    height: 60,
    padding: 15,
  },
  large: {
    width: 80,
    height: 80,
    padding: 20,
  },
  color: MAINCOLOR1,
};

export const administrationInfo = { color: MAINCOLOR1 };

export const administrationCheckBox = { fill: MAINCOLOR1 };

export const administrationArrow = {
  color: MAINCOLOR1,
  largeIcon: {
    width: 35,
    height: 35,
    bottom: '17.5px',
  },
  large: {
    width: 45,
    height: 45,
    left: '-25px',
    top: '-50px',
  },
};

export const administrationPaper = {
  height: '100%',
  width: '100%',
  margin: 0,
  backgroundColor: '#f8f8f8',
  boxShadow: '0.5px 0.5px 0.5px Gainsboro',
  textAlign: 'center',
  display: 'inline-block',
};

export const administrationSettingsButton = { color: MAINCOLOR1 };

export const administrationDatePicker = { textFieldStyle: { width: '140px' } };

export const administrationAddViewButton = {
  backgroundColor: MAINCOLOR1,
  zDepth: 1,
  mini: true,
};

export const administrationLeftPanel = {
  backgroundColor: 'white',
  color: 'black',
  iconColor: 'black',
  boxShadow: '1px 2px 2px lightgrey',
};

export const administrationGeneralPanels = {
  backgroundColor: MAINCOLOR1,
  color: 'White',
  iconColor: MAINCOLOR1,
};

export const administrationDrawer = {
  backgroundColor: '#f8f8f8',
  color: 'White',
  iconColor: 'white',
  arrowColor: MAINCOLOR1,
  top: '60px',
};

// -----------------------------------------------------------------------------
// -------------------------- KPIS VIEWS ---------------------------------------
export const kpisBar = { color: MAINCOLOR1 };

export const addButtonStyle = { color: MAINCOLOR1 };

// -----------------------------------------------------------------------------
// --------------------- DASHBOARD VIEWS ---------------------------------------
export const dashboardTop = { paddingTop: '60px' };

export const dashboardRemoveViewButton = { color: '#F48170' };

export const dashboardAddViewButton = {
  backgroundColor: '#4A72A8',
  zDepth: 1,
  mini: true,
};

export const dashboardBackButtonStyle = {
  smallIcon: {
    width: 18,
    height: 18,
    marginBottom: -3,
  },
  mediumIcon: {
    width: 30,
    height: 30,
    marginLeft: '-10px',
  },
  largeIcon: {
    width: 45,
    height: 45,
  },
  small: {
    width: 24,
    height: 24,
    padding: 2,
  },
  medium: {
    left: '0px',
    top: '25px',
    width: 60,
    height: 60,
    padding: 15,
  },
  large: {
    width: 80,
    height: 80,
    padding: 20,
  },
  color: '#4A72A8',
};

export const dashboardSaveViewButton = {
  backgroundColor: '#4A72A8',
  style: { marginTop: '5px' },
  zDepth: 1,
  mini: true,
};

export const dashboardArrowStyle = {
  color: '#4A72A8',
  largeIcon: {
    width: 35,
    height: 35,
    bottom: '17.5px',
  },
  large: {
    width: 45,
    height: 45,
    left: '-25px',
    top: '-50px',
  },
};

// -----------------------------------------------------------------------------
// ----------------------- KPIS DASHBOARDS -------------------------------------
export const kpisAppBar = {
  color: 'white',
  textColor: MAINCOLOR1,
};
// -----------------------------------------------------------------------------

// ---------------------------- MAP ---------------------------------------------
export const mapSimpleMarker = {
  smallIcon: {
    width: 18,
    height: 18,
    marginBottom: -3,
  },
  mediumIcon: {
    width: 30,
    height: 30,
  },
  largeIcon: {
    width: 45,
    height: 45,
    right: '10px',
    bottom: '4px',
    position: 'relative',
  },
  small: {
    width: 24,
    height: 24,
    padding: 2,
  },
  medium: {
    width: 60,
    height: 60,
    padding: 15,
  },
  large: {
    width: 45,
    height: 45,
    left: '-25px',
    top: '-50px',
  },
  colorLora: '#4A72A8',
  colorHttp: '#809CC2',
};

export const placeButtonStyle = {
  smallIcon: {
    width: 18,
    height: 18,
    marginBottom: -3,
  },
  mediumIcon: {
    width: 30,
    height: 30,
  },
  largeIcon: {
    width: 45,
    height: 45,
    right: '10px',
    bottom: '4px',
    position: 'relative',
  },
  small: {
    width: 24,
    height: 24,
    padding: 2,
  },
  medium: {
    width: 60,
    height: 60,
    padding: 15,
  },
  large: {
    width: 45,
    height: 45,
    left: '-25px',
    top: '-50px',
  },
  color: '#4A72A8',
};

export const mapClusterMarker = {
  smallIcon: {
    width: 18,
    height: 18,
    marginBottom: -3,
  },
  mediumIcon: {
    width: 30,
    height: 30,
  },
  largeIcon: {
    width: 45,
    height: 45,
    right: '11px',
    top: '-11px',
    position: 'relative',
  },
  small: {
    width: 24,
    height: 24,
    padding: 2,
  },
  medium: {
    width: 60,
    height: 60,
    padding: 15,
  },
  large: {
    width: 45,
    height: 45,
    left: '-20px',
    top: '-23px',
  },
  color: '#4A72A8',
  radius: '16',
  textColor: 'white',
  strokeWidth: '8',
};

export const mapAddDeviceButton = {
  style: {
    position: 'absolute',
    left: 'calc(100% - 45px)',
    top: '65px',
  },
  color: MAINCOLOR1,
};

export const mapMoveDeviceButton = {
  style: {
    position: 'absolute',
    left: 'calc(100% - 45px)',
    top: '110px',
  },
  style2: {
    position: 'absolute',
    left: 'calc(100% - 45px)',
    top: '65px',
  },
  color: MAINCOLOR1,
};

export const mapRemoveDeviceButton = {
  style: {
    position: 'absolute',
    left: 'calc(100% - 45px)',
    top: '165px',
  },
  color: MAINCOLOR2,
};

export const mapStylePaper = {
  height: '100%',
  width: '100%',
  margin: 0,
  backgroundColor: '#f8f8f8',
  boxShadow: '0.5px 0.5px 0.5px Gainsboro',
  textAlign: 'center',
  display: 'inline-block',
  arrowColor: MAINCOLOR1,
};

// -----------------------------------------------------------------------------

// --------------------------------- PLAN --------------------------------------
export const planTop = {
  not_integrated: { paddingTop: '50px' },
  integrated: { paddingTop: '10px' },
};

export const planStyle = {
  style: {
    width: '100%',
    height: '65vh',
    marginTop: '20px',
    zIndex: 0,
  },
};

export const picker = {
  style: {
    marginTop: '20px',
    marginLeft: '20px',
    zIndex: 0,
    marginBottom: '30px',
  },
};

export const planArrowStyle = {
  color: MAINCOLOR1,
  largeIcon: {
    width: 35,
    height: 35,
    bottom: '17.5px',
  },
  large: {
    width: 45,
    height: 45,
    left: '-25px',
    top: '-50px',
  },
};

export const planMoveDeviceButton = {
  colorDisabled: 'lightgrey',
  style: {
    marginTop: '5px',
    marginRight: '5px',
  },
  zDepth: 0,
  mini: true,
  colorEnabled: MAINCOLOR1,
  boxShadow: 'none',
};

export const planDeleteDeviceButton = {
  colorDisabled: 'lightgrey',
  style: {
    marginTop: '5px',
    marginRight: '5px',
  },
  zDepth: 0,
  mini: true,
  colorEnabled: MAINCOLOR2,
  boxShadow: 'none',
};

export const planImportButton = {
  colorDisabled: 'lightgrey',
  style: {
    marginTop: '5px',
    marginRight: '5px',
  },
  zDepth: 0,
  mini: true,
  colorEnabled: MAINCOLOR1,
  boxShadow: 'none',
};

export const planAddDeviceButton = {
  colorDisabled: 'lightgrey',
  style: {
    marginTop: '5px',
    marginRight: '5px',
    width: '70px',
  },
  zDepth: 1,
  mini: true,
  colorEnabled: MAINCOLOR1,
  boxShadow: 'none',
};

export const planAddDeviceImage = {
  style: {
    position: 'relative',
    width: '70px',
    height: '70px',
    zIndex: 1000,
    cursor: 'pointer',
    padding: '5px',
  },
  style_selected: {
    position: 'relative',
    width: '70px',
    height: '70px',
    zIndex: 1000,
    cursor: 'pointer',
    padding: '5px',
    border: '2px solid #4ECDC4',
  },
  style_disabled: {
    position: 'relative',
    width: '70px',
    height: '70px',
    zIndex: 1000,
    cursor: 'pointer',
    padding: '5px',
    opacity: '0.3',
  },
  color: '#4ECDC4',
};
// -----------------------------------------------------------------------------

export const backButtonStyle = {
  smallIcon: {
    width: 18,
    height: 18,
    marginBottom: -3,
  },
  mediumIcon: {
    width: 30,
    height: 30,
    marginLeft: '-10px',
  },
  largeIcon: {
    width: 45,
    height: 45,
  },
  small: {
    width: 24,
    height: 24,
    padding: 2,
  },
  medium: {
    left: '0px',
    top: '25px',
    width: 60,
    height: 60,
    padding: 15,
  },
  large: {
    width: 80,
    height: 80,
    padding: 20,
  },
  color: '#4A72A8',
};

export const switchStyle = {
  block: { maxWidth: 250 },
  toggle: { marginBottom: 15 },
  thumbOff: { backgroundColor: 'white' },
  trackOff: { backgroundColor: '#F48170' },
  thumbON: { backgroundColor: 'white' },
  trackON: { backgroundColor: '#F48170' },
  labelStyle: { color: 'red' },
};

export const arrowStyle = {
  color: '#F48170',
  largeIcon: {
    width: 35,
    height: 35,
    bottom: '17.5px',
  },
  large: {
    width: 45,
    height: 45,
    left: '-25px',
    top: '-50px',
  },
};

// export const tabsStyle = getMuiTheme({
//   tabs: {
//     backgroundColor: 'white',
//     textColor: 'grey',
//     selectedTextColor: 'black',
//   },
// });

export const mapStepperStyle = {
  stepper: { backgroundColor: 'rgba(78, 205, 196, 0.3)' },
  text: { color: '#4ECDC4' },
  icon: { color: '#4ECDC4' },
};

export const StepperStyle = {
  text: { color: '#4ECDC4' },
  icon: { color: '#4ECDC4' },
};

export const tabStyle = { inkBarStyle: { backgroundColor: MAINCOLOR2 } };

export const checkBoxStyle = { fill: '#4ECDC4' };

export const infoButtonStyle = { color: '#4ECDC4' };

export const settingsButtonStyle = { color: '#4ECDC4' };

export const createButtonStyle = {
  smallIcon: {
    width: 18,
    height: 18,
    marginBottom: -3,
  },
  mediumIcon: {
    width: 24,
    height: 24,
    marginBottom: -6,
  },
  largeIcon: {
    width: 60,
    height: 60,
  },
  small: {
    width: 24,
    height: 24,
    padding: 2,
  },
  medium: {
    width: 27,
    height: 27,
    padding: 2,
  },
  large: {
    width: 120,
    height: 120,
    padding: 30,
  },
  color: '#4ECDC4',
};

export const ZoomButtonStyle = {
  smallIcon: {
    width: 18,
    height: 18,
    marginBottom: -3,
  },
  mediumIcon: {
    width: 30,
    height: 30,
  },
  largeIcon: {
    width: 45,
    height: 45,
    right: '10px',
    bottom: '5px',
    position: 'relative',
  },
  small: {
    width: 24,
    height: 24,
    padding: 2,
  },
  medium: {
    width: 60,
    height: 60,
    padding: 15,
  },
  large: {
    width: 45,
    height: 45,
    left: '0px',
    top: '-15px',
  },
  color: '#4A72A8',
};

export const exportcsvButtonStyle = {
  smallIcon: {
    width: 18,
    height: 18,
    marginBottom: -3,
  },
  mediumIcon: {
    width: 30,
    height: 30,
  },
  largeIcon: {
    width: 45,
    height: 45,
    right: '10px',
    bottom: '5px',
    position: 'relative',
  },
  small: {
    width: 24,
    height: 24,
    padding: 2,
  },
  medium: {
    width: 60,
    height: 60,
    padding: 15,
  },
  large: {
    width: 45,
    height: 45,
    left: '0px',
    top: '-15px',
  },
  color: '#4A72A8',
};

export const ZoomButtonViewsStyle = {
  smallIcon: {
    width: 25,
    height: 25,
    marginBottom: -3,
    right: '4px',
    bottom: '4px',
    position: 'relative',
  },
  mediumIcon: {
    width: 30,
    height: 30,
    right: '5px',
    bottom: '5px',
    position: 'relative',
  },
  largeIcon: {
    width: 45,
    height: 45,
    right: '10px',
    bottom: '5px',
    position: 'relative',
  },
  small: {
    width: 30,
    height: 30,
    padding: 6,
    left: '35px',
    top: '10px',
    position: 'absolute',
  },
  medium: {
    width: 45,
    height: 45,
    left: '0px',
    top: '-20px',
  },
  large: {
    width: 45,
    height: 45,
    left: '0px',
    top: '-10px',
  },
  color: '#4A72A8',
};

export const exportcsvButtonViewsStyle = {
  smallIcon: {
    width: 18,
    height: 18,
    marginBottom: -3,
  },
  mediumIcon: {
    width: 30,
    height: 30,
  },
  largeIcon: {
    width: 45,
    height: 45,
    right: '10px',
    bottom: '5px',
    position: 'relative',
  },
  small: {
    width: 24,
    height: 24,
    padding: 2,
  },
  medium: {
    width: 60,
    height: 60,
    padding: 15,
  },
  large: {
    width: 45,
    height: 45,
    left: '0px',
    top: '-10px',
  },
  color: '#4A72A8',
};

export const styleDevicePaper = {
  height: '100%',
  width: '100%',
  margin: 0,
  backgroundColor: 'white',
  boxShadow: '0.5px 0.5px 0.5px Gainsboro',
  textAlign: 'center',
  display: 'inline-block',
};

export const createAdminIcon = {
  smallIcon: {
    width: 18,
    height: 18,
    marginBottom: -3,
  },
  mediumIcon: {
    width: 24,
    height: 24,
    marginBottom: -6,
  },
  largeIcon: {
    width: 60,
    height: 60,
  },
  small: {
    width: 24,
    height: 24,
    padding: 2,
  },
  medium: {
    width: 27,
    height: 27,
    padding: 2,
    left: '130px',
    top: '27px',
  },
  large: {
    width: 120,
    height: 120,
    padding: 30,
  },
  color: '#4A72A8',
};

export const styleRemoveUserButton = { color: '#F48170' };

export const stylePaper = {
  height: '100%',
  width: '100%',
  margin: 0,
  backgroundColor: '#f8f8f8',
  boxShadow: '0.5px 0.5px 0.5px Gainsboro',
  textAlign: 'center',
  display: 'inline-block',
  arrowColor: MainColor,
};
