import React from 'react';  //eslint-disable-line
import PropTypes from 'prop-types'; //eslint-disable-line

export default function Body({ children }) {
  return (
    <React.Fragment key="body">
      <div id="body" className="d-print-none">
        {children}
      </div>
    </React.Fragment>
  );
}
Body.propTypes = {
  children: PropTypes.element.isRequired,
};
