import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';

// -------------------------- Reducers --------------------------
import Reducer from '../reducers/index';

export default function configureStore(initialState) {
  const store = createStore(
    Reducer,
    initialState,
    applyMiddleware(thunkMiddleware),
  );

  return store;
}
