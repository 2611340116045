import React from 'react'; // eslint-disable-line
import Responsive from 'react-responsive'; // eslint-disable-line
import PropTypes from 'prop-types'; // eslint-disable-line

// Desktop, tablet and mobile setup
export const ExtraLarge = ({ children }) => (
  <Responsive minWidth={1200} children={children} />
);
ExtraLarge.propTypes = {
  children: PropTypes.element.isRequired,
};
export const LargeOnly = ({ children }) => (
  <Responsive minWidth={992} maxWidth={1199} children={children} />
);
LargeOnly.propTypes = {
  children: PropTypes.element.isRequired,
};
export const Large = ({ children }) => (
  <Responsive minWidth={992} children={children} />
);
Large.propTypes = {
  children: PropTypes.element.isRequired,
};
export const MediumOnly = ({ children }) => (
  <Responsive minWidth={768} maxWidth={991} children={children} />
);
MediumOnly.propTypes = {
  children: PropTypes.element.isRequired,
};
export const MediumUp = ({ children }) => (
  <Responsive minWidth={768} children={children} />
);
MediumUp.propTypes = {
  children: PropTypes.element.isRequired,
};
export const Medium = ({ children }) => (
  <Responsive maxWidth={991} children={children} />
);
Medium.propTypes = {
  children: PropTypes.element.isRequired,
};
export const Small = ({ children }) => (
  <Responsive maxWidth={767} children={children} />
);
Small.propTypes = {
  children: PropTypes.element.isRequired,
};
// const Mobile = ({ children }) => <Responsive maxWidth={768} children={children} />;
