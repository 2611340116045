import { combineReducers } from 'redux'; //eslint-disable-line

// ------------------------ Reducers ---------------------------
import Auth from './auth';


const Reducer = combineReducers({
  Auth,
});

export default Reducer;
