import React from 'react'; //eslint-disable-line
import PropTypes from 'prop-types'; //eslint-disable-line
import Loader from 'components/material_ui/loader';
import { Large, Medium } from 'components/utils/mediaQueryDefinition';

export default function MainLoader({ imagePath, href, alt }) {
  return (
    <div>
      <Large>
        <div>
          <div className="main container-fluid">
            <div
              className="row align-items-center justify-content-center"
              style={{ paddingTop: '250px' }}
            >
              <Loader />
            </div>
            {imagePath ? (
              <div className="row align-items-center justify-content-center">
                <a href={href} target="_blank" rel="noopener noreferrer" style={{ textAlign: 'center' }}>
                  <img
                    src={imagePath}
                    style={{ marginTop: '65px', maxWidth: 'calc(100% - 30px)' }}
                    alt={alt}
                    height="150"
                  />
                </a>
              </div>
            ) : null}
          </div>
        </div>
      </Large>
      <Medium>
        <div>
          <div className="main container-fluid">
            <div
              className="row align-items-center justify-content-center"
              style={{ paddingTop: '100px' }}
            >
              <Loader />
            </div>
            <div className="row align-items-center justify-content-center">
              <a href={href} target="_blank" rel="noopener noreferrer" style={{ textAlign: 'center' }}>
                <img
                  src={imagePath}
                  style={{ marginTop: '50px', maxWidth: 'calc(100% - 30px)' }}
                  alt={alt}
                  height="100"
                />
              </a>
            </div>
          </div>
        </div>
      </Medium>
    </div>
  );
}
MainLoader.propTypes = {
  imagePath: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};
